

















































































import { Component, Vue } from 'vue-property-decorator'
import { apiPaymentMethodGet } from '@/api/setting/payment.ts'
// import * as Interface from '@/api/setting/payment.d.ts'
import { del } from '@vue/composition-api'
// import th from 'element-ui/src/locale/lang/th'
@Component({
    components: {}
})
export default class PayMethod extends Vue {
    /** S Data **/
    paymentMethodData: Array<Object> = []
    /** E Data **/

    /** S Methods **/

    // 获取支付方式数据
    getPaymentMethodList() {
        apiPaymentMethodGet()
            .then((res: any) => {
                this.paymentMethodData = res
                console.log('paymentMethodData', this.paymentMethodData)
                for (let i in this.paymentMethodData) {
                    // console.log('i', i)
                    if (i != '1') {
                        delete this.paymentMethodData[i]
                    }
                }
            })
            .catch(() => {
                this.$message.error('数据初始化失败，请刷新重载！')
            })
    }

    onSetPayMethod() {
        this.$router.push('/setting/payment/pay_method_edit')
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.getPaymentMethodList()
    }
    /** E Life Cycle **/
}
